import Swiper from "swiper";
import "./scss/block4.scss";

window.addEventListener("load", () => {
    const $this = $("#block4");

    const charThumbSwiper = new Swiper("#character-pagination", {
        slidesPerView: 4, freeMode: true, watchSlidesProgress: true, spaceBetween: 10, breakpoints: {
            999: {
                slidesPerView: 7,
            }
        }
    });
    const characterSwiper = new Swiper(`#character-swiper-container .swiper`, {
        preloadImages: true, lazy: true, thumbs: {
            swiper: charThumbSwiper
        }, navigation: {
            prevEl: `#character-swiper-container .swiper-button-prev`,
            nextEl: `#character-swiper-container .swiper-button-next`,
        },
    });
    paginationSwiperInit("#character-pagination .nav-button", characterSwiper, charThumbSwiper);
    const weaponSwiper = new Swiper(`#weapon-swiper-container .swiper`, {
        direction: "horizontal", preloadImages: false, lazy: true, navigation: {
            prevEl: `#weapon-swiper-container .swiper-button-prev`,
            nextEl: `#weapon-swiper-container .swiper-button-next`,
        },
    });

    const weaponPagination = new Swiper("#weapon-pagination", {
        direction: "horizontal",
        preloadImages: false,
        lazy: true,
        initialSlide: 0,
        slidesPerView: 3,
        spaceBetween: 16,
        centeredSlides: true,
        centeredSlidesBounds: true,
        breakpoints: {
            769: {
                slidesPerView: 5,
            },
        },
    });
    paginationSwiperInit("#weapon-pagination .nav-button", weaponSwiper, weaponPagination);

    const vehicleSwiper = new Swiper(`#vehicle-swiper-container .swiper`, {
        direction: "horizontal", preloadImages: false, lazy: true,

        navigation: {
            prevEl: `#vehicle-swiper-container .swiper-button-prev`,
            nextEl: `#vehicle-swiper-container .swiper-button-next`,
        },
    });

    const vehiclePagination = new Swiper(`#vehicle-pagination`, {
        direction: "horizontal",
        preloadImages: false,
        lazy: true,
        initialSlide: 0,
        slidesPerView: 3,
        spaceBetween: 16,
        centeredSlides: true,
        centeredSlidesBounds: true,
        breakpoints: {
            769: {
                slidesPerView: 5,
            },
        },
    });
    paginationSwiperInit("#vehicle-pagination .nav-button", vehicleSwiper, vehiclePagination);

    // top nav menu interaction
    (() => {
        let activeIndex = 0;
        const swiperContainers = $this.find(".swiper-container");
        swiperContainers.eq(activeIndex).addClass("active");
        const topNavButtons = $this.find(".top-nav-container button");
        topNavButtons.each(function (index, el) {
            $(el).on("click", function (event) {
                topNavButtons.eq(activeIndex).toggleClass("active");
                swiperContainers.eq(activeIndex).toggleClass("active");
                activeIndex = index;
                topNavButtons.eq(activeIndex).toggleClass("active");
                swiperContainers.eq(activeIndex).toggleClass("active");

                // TODO: change swiper tab here
            });
        });
    })();
}, {once: true});

const paginationSwiperInit = (navQuery, controller, pagination) => {
    let activeIndex = 0;
    const navs = $(navQuery);
    navs.eq(activeIndex).addClass("active");

    navs.each(function (index, el) {
        $(el).on("click", () => {
            navs.eq(activeIndex).removeClass("active");
            activeIndex = index;
            navs.eq(activeIndex).addClass("active");
            controller.slideTo(index);
            pagination.slideTo(index);
        });
    });

    pagination.on("slideChange", (p) => {
        navs.eq(activeIndex).removeClass("active");
        activeIndex = p.activeIndex;
        navs.eq(activeIndex).addClass("active");
        controller.slideTo(activeIndex);
    });

    controller.on("slideChange", (c) => {
        navs.eq(activeIndex).removeClass("active");
        activeIndex = c.activeIndex;
        navs.eq(activeIndex).addClass("active");
        pagination.slideTo(activeIndex);
    });
};
