import "./scss/guide.scss";

window.addEventListener(
	"load",
	() => {
		const $this = $("#guide");
		if ($this.length === 0) {
			return;
		}

		(() => {
			let currentId = 0;
			const navButton = $this.find(".nav-container button");
			navButton.each(function (index, el) {
				$(el).on("click", function (event) {
					navButton.eq(currentId).toggleClass("active");
					currentId = index;
					navButton.eq(currentId).toggleClass("active");
				});
			});
		})();

		const news = $this.find(".news");
		const first = $this.find(".pagination-wrapper .btn-first");
		const last = $this.find(".pagination-wrapper .btn-last");
		let currentStart = 1;
		let currentEnd = 5;

		// if ($this.find(".pagination") > 0) {
		// 	$this.find(".pagination").pagination({
		// 		items: news.length,
		// 		itemsOnPage: 5,
		// 		displayedPages: 3,
		// 		edges: 1,
		// 		prevText: "<",
		// 		nextText: ">",
		// 		ellipsePageSet: false,
		// 		onPageClick: (pageNumber, event) => {
		// 			if (pageNumber > 1) {
		// 				first.removeClass("disabled");
		// 			} else {
		// 				first.addClass("disabled");
		// 			}
		// 			if (pageNumber < $this.find(".pagination").pagination("getPagesCount")) {
		// 				last.removeClass("disabled");
		// 			} else {
		// 				last.addClass("disabled");
		// 			}

		// 			console.log(currentStart, currentEnd);
		// 			for (let i = currentStart; i <= currentEnd; i++) {
		// 				if (i - 1 < 0 || i - 1 >= news.length) continue;
		// 				news.eq(i - 1).addClass("hide");
		// 			}

		// 			currentStart = 5 * (pageNumber - 1) + 1;
		// 			currentEnd = 5 * pageNumber;
		// 			console.log(currentStart, currentEnd);
		// 			for (let i = currentStart; i <= currentEnd; i++) {
		// 				if (i - 1 < 0 || i - 1 >= news.length) continue;
		// 				news.eq(i - 1).removeClass("hide");
		// 			}
		// 		},
		// 		onInit: () => {
		// 			const numberOfPages = $this.find(".pagination").pagination("getPagesCount");
		// 			for (let i = currentStart; i <= currentEnd; i++) {
		// 				if (i - 1 < 0 || i - 1 >= news.length) continue;
		// 				news.eq(i - 1).removeClass("hide");
		// 			}
		// 			first.addClass("disabled");

		// 			first.on("click", function (e) {
		// 				$this.find(".pagination").pagination("selectPage", 1);
		// 			});

		// 			last.on("click", function (e) {
		// 				$this.find(".pagination").pagination("selectPage", numberOfPages);
		// 			});
		// 		},
		// 	});
		// }

		if ($this.find(".custom-news-select").length > 0) {
			$this.find(".custom-news-select").customSelect({});
		}
	},
	{once: true}
);
