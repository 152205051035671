import "./scss/faqs.scss";

window.addEventListener(
	"load",
	() => {
		const $this = $("#faqs");
		if ($this.length === 0) {
			return;
		}

		(() => {
			let currentId = 0;
			const navButton = $this.find(".nav-container button");
			navButton.each(function (index, el) {
				$(el).on("click", function (event) {
					navButton.eq(currentId).toggleClass("active");
					currentId = index;
					navButton.eq(currentId).toggleClass("active");
				});
			});
		})();

		(() => {
			let activeTab = null;
			const accordions = $this.find(".accordion");
			accordions.each(function (index, el) {
				const toggle = $(el).find(".accordion-top");
				toggle.on("click", function (e) {
					if (index === activeTab) {
						$(el).removeClass("active");
						activeTab = null;
						setTimeout(() => {
							$("#wrapper").scalePlatform();
						}, 330);
						return;
					}
					setTimeout(() => {
						$(el).addClass("active");
					}, 30);
					if (activeTab !== null) {
						accordions.eq(activeTab).removeClass("active");
					}
					activeTab = index;
					const interval = setInterval(() => {
						$("#wrapper").scalePlatform();
					}, 50);
					setTimeout(() => {
						clearInterval(interval);
					}, 500);
				});
			});
		})();
	},
	{once: true}
);
