import "./js/scaleRoot.2.js";
import "./js/_vportal.js";
//import "./js/_language.js";
import "./js/scrollwatch.js";
import scrollFrame from "./js/scrollFrame.2.js";
import "./js/customSelect.js";
import "./js/setup.js";
import "./js/_swiper.js";
import "@fancyapps/fancybox";
import "./scss/fancybox-custom.scss";
import "animate.css";
// scss
import "./scss/_config.scss";
import "./scss/_common.scss";
import "./scss/_loader.scss";
import "./scss/componentSize.scss";

import lozad from "lozad";

import Swiper, {Pagination, Navigation, EffectCoverflow} from "swiper";
Swiper.use([Navigation, Pagination, EffectCoverflow]);

window.addEventListener("load", () => {
	const observer = lozad(".lazyload", {
		loaded: function (el) {
			el.classList.add("is-loaded");
		},
	});
	observer.observe();

	// scroll frame setup
	const frameIds = scrollFrame.el.frame.map((f) => f.el);
	const navs = $(".scrollFrameControl");
	const pins = $(".scrollwatch-pin");
	pins.each(function (index, el) {
		$(el).scrollWatch({
			options: {
				watch: `${frameIds[index]} .scrollwatch-pin`,
				watchOnce: false,
				onElementInView: function () {
					if (scrollFrame.data.aniComplete === true) {
						const currentFrame = scrollFrame.data.currentFrame;
						navs.eq(currentFrame).removeClass("active");
						scrollFrame.data.currentFrame = index;
						navs.eq(index).addClass("active");
					} else {
					}
				},
			},
		});
	});

	$(".news-bg").each(function () {
		var postsTag = "#" + $(this).attr("id");
		// console.log(postsTag);
		$().vPortal({
			container: postsTag,
			el: {
				tabContainer: ".nav-container",
				resultContainer: ".news-list",
				containerSuffix: " button",
			},
			paginationOptions: {},
		});
	});
});
