import Swiper from "swiper";
import "./scss/block3.scss";

window.addEventListener(
	"load",
	() => {
		const $this = $("#block3");
		if ($this.length === 0) {
			return;
		}
		const bannerSwiper = new Swiper(".banner-swiper-container .swiper", {
			direction: "horizontal",

			navigation: {
				prevEl: ".banner-swiper-container .swiper-button-prev",
				nextEl: ".banner-swiper-container .swiper-button-next",
			},
			pagination: {
				el: ".banner-swiper-container .swiper-pagination",
				clickable: true,
			},
		});

		(() => {
			let currentSlide = 0;
			const banners = $this.find(".banner");
			banners.eq(currentSlide).addClass("active");
			bannerSwiper.on("slideChange", function (swiper) {
				banners.eq(currentSlide).removeClass("active");
				currentSlide = swiper.activeIndex;
				banners.eq(currentSlide).addClass("active");
			});
		})();

		(() => {
			let currentId = 0;
			const navButton = $this.find(".nav-container button");
			navButton.each(function (index, el) {
				$(el).on("click", function (event) {
					navButton.eq(currentId).toggleClass("active");
					currentId = index;
					navButton.eq(currentId).toggleClass("active");
				});
			});
		})();
	},
	{once: true}
);
