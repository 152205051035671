import "./scss/details.scss";

window.addEventListener(
	"load",
	() => {
		const $this = $("#details");
		if ($this.length === 0) {
			return;
		}
		(() => {
			let currentId = 0;
			const navButton = $this.find(".nav-container a");
			navButton.each(function (index, el) {
				$(el).on("click", function (event) {
					navButton.eq(currentId).toggleClass("active");
					currentId = index;
					navButton.eq(currentId).toggleClass("active");
				});
			});
		})();

		if ($this.find(".custom-news-select").length > 0) {
			$this.find(".custom-news-select").customSelect();
		}

		(() => {
			let currentId = 0;
			const navButton = $this.find(".nav-container button");
			navButton.each(function (index, el) {
				$(el).on("click", function (event) {
					navButton.eq(currentId).toggleClass("active");
					currentId = index;
					navButton.eq(currentId).toggleClass("active");
				});
			});
		})();
	},
	{once: true}
);
