import "./scss/float_bottom.scss";

window.addEventListener(
	"load",
	function (e) {
		const $this = $("#float_bottom");
		if ($this.length === 0) {
			return;
		}
		const navMenu = $this.find(".navigation");
		const languageCheck = $this.find(".currentInput");

		navMenu.removeClass("active");

		$(".btn-menu").on("click", function (e) {
			navMenu.toggleClass("active");
			languageCheck.prop("checked", false);
		});

		languageCheck.on("click", function (e) {
			if ($(this).is(":checked")) {
				navMenu.removeClass("active");
			} else {
			}
		});
	},
	{once: true}
);
