import Swiper from "swiper";
import "./scss/block5.scss";

window.addEventListener(
	"load",
	() => {
		const $this = $("#block5");
		if ($this.length === 0) {
			return;
		}
		const hotFeatureSwiper = new Swiper("#hot-feature-swiper", {
			initialSlide: 1,
			direction: "horizontal",
			slidesPerView: "auto",
			loop: true,
			effect: "coverflow",
			coverflowEffect: {
				rotate: 0,
				stretch: 203,
				depth: 80,
				modifier: 2,
				slideShadows: false,
			},
			breakpoints: {
				769: {
					coverflowEffect: {
						rotate: 0,
						stretch: 280,
						depth: 80,
						modifier: 2,
						slideShadows: false,
					},
				}
			},

			pagination: {
				el: "#hot-feature-swiper .swiper-pagination",
				clickable: true,
			},

			navigation: {
				prevEl: ".block5-swiper-container .swiper-button-prev",
				nextEl: ".block5-swiper-container .swiper-button-next",
			},
		});
	},
	{once: true}
);
