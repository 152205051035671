import scrollFrame from "./scrollFrame.2.js";

// Scale
window.addEventListener("load", function () {
	$("#wrapper").scalePlatform();
	scrollFrame.init();

	$(window).on("resize", function () {
		$("#wrapper").scalePlatform();
		// scrollFrame.init();
	});
});

// fancybox
$(".click-openpopup").on("click", function (e) {
	const popupId = $(this).attr("data-href");
	$.fancybox.open({
		src: "#popup-base",
		type: "inline",
		afterLoad: function () {
			$("html").addClass("popup-opened");
			$(popupId).addClass("active");
		},
		afterClose: function () {
			$("html").removeClass("popup-opened");
			$(popupId).removeClass("active");
		},
	});
});
